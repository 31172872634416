import * as React from "react";
import styles from "./RequiredDocuments.module.scss";
import { IRequiredDocument } from "../../models/RequiredDocument";
import { ServiceContext } from "../../Services/ServiceContext";
import css from "classnames";
export interface IRequiredDocumentsProps {
	loanAppId: string;
}

export function RequiredDocuments(props: IRequiredDocumentsProps) {
	const ctx = React.useContext(ServiceContext);
	const [messageCount, setMessageCount] = React.useState(0);
	const [requiredDocs, setRequiredDocs] = React.useState<IRequiredDocument[]>([]);

	React.useEffect(() => {
		let obvs = ctx.fileService
			.GetRequiredDocuments(props.loanAppId)
			.subscribe((requiredDocuments) => {
				setRequiredDocs(requiredDocuments);
			});
		return () => {
			obvs.unsubscribe();
		};
	}, [props.loanAppId, ctx.fileService]);

	const docs = React.useMemo(() => {
		return requiredDocs.filter((i) => !i.userCreated);
	}, [requiredDocs]);
	const otherDocs = React.useMemo(() => {
		return requiredDocs.filter((i) => i.userCreated && i.hasDocuments);
	}, [requiredDocs]);
	return (
		<div
			className={styles.requiredDocuments}
			onContextMenu={(e) => {
				if (e.shiftKey) {
					ctx.fileService.SendNotification({
						bodyText: <span>HELLO WORLD {messageCount + 1}</span>,
						dismissAfter: 5000,
					});
					setMessageCount(messageCount + 1);
					e.preventDefault();
				}
			}}>
			{docs.length > 0 && (
				<>
					<div>
						<b>Required Documents</b>
					</div>
					{docs.map((i) => (
						<div className={styles.docLine} key={i.documentType}>
							<div className={css(styles.icon, i.hasDocuments ? "text-success" : "text-danger")}>
								{i.hasDocuments ? (
									<i className="bi bi-check-lg"></i>
								) : (
									<i className="bi bi-x-lg"></i>
								)}
							</div>
							<div>{i.docTypeLabel}</div>
						</div>
					))}
				</>
			)}

			{otherDocs.length > 0 && (
				<>
					<div>
						<b>Other Documents</b>
					</div>
					{otherDocs.map((i) => (
						<div className={styles.docLine} key={i.documentType}>
							<div className={css(styles.icon, i.hasDocuments ? "text-success" : "text-danger")}>
								{i.hasDocuments ? (
									<i className="bi bi-check-lg"></i>
								) : (
									<i className="bi bi-x-lg"></i>
								)}
							</div>
							<div>{i.docTypeLabel}</div>
						</div>
					))}
				</>
			)}
		</div>
	);
}
