import * as React from "react";
import { IRequiredDocument } from "../../models/RequiredDocument";
import { ServiceContext } from "../../Services/ServiceContext";

export interface IUploadedFileStatusProps {
	loanAppId: string;
}

export function UploadedFileStatus(props: IUploadedFileStatusProps) {
	const ctx = React.useContext(ServiceContext);
	const [requiredDocs, setRequiredDocs] = React.useState<IRequiredDocument[]>([]);
	React.useEffect(() => {
		let obvs = ctx.fileService.GetRequiredDocuments(props.loanAppId).subscribe((docs) => {
			setRequiredDocs(docs);
		});

		return () => {
			obvs.unsubscribe();
		};
	}, [props.loanAppId, ctx.fileService]);

	const [required, uploaded, pending] = React.useMemo(() => {
		let ucDocs = requiredDocs.filter((i) => !i.userCreated);
		let uploaded = requiredDocs.filter((i) => i.hasDocuments);
		let pending = ucDocs.filter((i) => !i.hasDocuments);
		return [ucDocs.length, uploaded.length, pending.length];
	}, [requiredDocs]);

	return (
		<div>
			<div>
				<b> Required Document: </b>
				<span>{required}</span>
			</div>
			<div>
				<b>Uploaded Documents: </b>
				<span>{uploaded}</span>
			</div>
			<div>
				<b>Pending Documents: </b>
				<span>{pending}</span>
			</div>
		</div>
	);
}
