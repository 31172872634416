import * as React from "react";
import { Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import styles from "./UploadForm.module.scss";
import { IPickListValue } from "../../models/PickListValue";
import { ServiceContext } from "../../Services/ServiceContext";
import { FileControl } from "../FileControl/FileControl";
import { Select } from "../Select/Select";
import toast from "react-hot-toast";
export interface IUploadFormProps {
	id: string;
}
export interface IUploadFormValue {
	file?: File;
	fileType?: string;
}
const schema = yup.object().shape({
	file: yup.mixed<File>().required(),
	fileType: yup.string().required(),
});

const initialValue: IUploadFormValue = {};
export function UploadForm(props: IUploadFormProps) {
	const [docTypes, setDocTypes] = React.useState<IPickListValue[]>([]);
	const [firstSubmit, setFirstSubmit] = React.useState<boolean>(false);
	const [allowedFileTypes, setAllowedFileTypes] = React.useState<string[]>([]);
	const ctx = React.useContext(ServiceContext);

	React.useEffect(() => {
		let obvs = ctx.fileService.GetDocumentTypes().subscribe((i) => {
			setDocTypes(i);
		});

		return () => {
			obvs.unsubscribe();
		};
	}, [ctx]);

	React.useEffect(() => {
		let unloaded = false;
		ctx.fileService.GetAllowedFileTypes().then((i) => {
			if (!unloaded) setAllowedFileTypes(i);
		});

		return () => {
			unloaded = true;
		};
	}, [ctx]);
	return (
		<div className={styles.uploadForm}>
			<Formik
				validationSchema={schema}
				initialValues={initialValue}
				validateOnBlur
				validateOnChange
				onSubmit={(values, actions) => {
					let vals = { ...values };

					let toastId = toast.loading("Uploading...");

					actions.setSubmitting(true);
					ctx.fileService
						.UploadFile(props.id, vals.fileType, vals.file)
						.then(() => {
							return ctx.fileService.RefreshRequiredDocuments(props.id);
						})
						.then(() => {
							toast.dismiss(toastId);
							toast.success("File was succesfully uploaded!", {
								duration: 10000,
							});
							actions.setFieldValue("file", null);
							actions.setFieldValue("fileType", null);
							actions.resetForm({});
							setFirstSubmit(false);
						})
						.catch((ex) => {
							toast.dismiss(toastId);
							toast.error(ex.message);
						})
						.finally(() => {
							actions.setSubmitting(false);
						});
				}}>
				{({
					handleSubmit,
					handleChange,
					handleBlur,
					values,
					touched,
					isValid,
					isSubmitting,
					errors,
					setFieldError,
					setFieldValue,
					setTouched,
					setFieldTouched,
					isValidating,
				}) => (
					<Form
						noValidate
						onSubmit={(e) => {
							setFirstSubmit(true);
							handleSubmit(e);
						}}>
						<Form.Group className={styles.formGroup}>
							<Form.Label className={styles.required}>Choose Document</Form.Label>
							<div style={{ position: "relative" }}>
								<FileControl
									name="file"
									type="file"
									required
									disabled={isSubmitting}
									accept={allowedFileTypes.join(",")}
									files={[values.file]}
									onBlur={handleBlur}
									onFilesChanged={(files) => {
										setFieldValue("file", files[0]);
									}}
									isInvalid={(firstSubmit || touched.file) && !!errors.file}
								/>
								<Form.Control.Feedback tooltip type="invalid">
									Please Choose a document
								</Form.Control.Feedback>
							</div>
							<Form.Text style={{ fontSize: "12px" }} muted>
								Valid file formats are PDF, PNG and JPEG and limit up to 20MB.
							</Form.Text>
						</Form.Group>
						<Form.Group className={styles.formGroup}>
							<Form.Label className={styles.required}>Choose Document Type</Form.Label>
							<Select
								name="fileType"
								required
								disabled={isSubmitting}
								value={values.fileType}
								onBlur={handleBlur}
								values={docTypes.map((i) => ({ key: i.value, label: i.label }))}
								onChange={handleChange}
								isInvalid={(firstSubmit || touched.fileType) && !!errors.fileType}></Select>
							<Form.Control.Feedback tooltip type="invalid">
								<span>Please Choose a document type</span>
							</Form.Control.Feedback>
						</Form.Group>
						<div className={styles.buttonRow}>
							<Button disabled={isSubmitting} type="submit">
								Submit
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}
