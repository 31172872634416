import * as React from "react";
import { useParams } from "react-router-dom";
import { RequiredDocuments } from "../RequiredDocuments/RequiredDocuments";
import { UploadForm } from "../UploadForm/UploadForm";
import { UploadedFileStatus } from "../UploadedFileStatus/UploadedFileStatus";
import { Alert, Spinner } from "react-bootstrap";
import { ServiceContext } from "../../Services/ServiceContext";
import styles from "./Upload.module.scss";
import { IRequiredDocument } from "../../models/RequiredDocument";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";
export interface IUploadProps {}

export function Upload(props: IUploadProps): JSX.Element {
	const { id } = useParams<{ id: string }>();
	const [valid, setValid] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [requiredDocs, setRequiredDocs] = React.useState<IRequiredDocument[]>(null);
	const ctx = React.useContext(ServiceContext);
	React.useEffect(() => {
		setLoading(true);
		let obvs = from(ctx.fileService.ValidateLoanId(id))
			.pipe(
				mergeMap((value) => {
					setLoading(true);
					if (value) {
						setValid(true);
						return ctx.fileService.GetRequiredDocuments(id);
					} else {
						setValid(false);
						setRequiredDocs([]);
						setLoading(false);
						return [];
					}
				})
			)
			.subscribe((docs) => {
				setRequiredDocs(docs);
				setLoading(false);
			});
		return () => obvs.unsubscribe();
	}, [id, ctx.fileService]);

	React.useEffect(() => {
		console.log("LOADING", loading);
	}, [loading]);

	return (
		<div className={styles.upload}>
			{!loading && valid && (
				<div>
					<div>
						<Alert variant="info">
							<UploadedFileStatus loanAppId={id}></UploadedFileStatus>
						</Alert>
					</div>
					<hr />

					{requiredDocs.length > 0 && (
						<>
							<div>
								<Alert variant="info">
									<RequiredDocuments loanAppId={id}></RequiredDocuments>
								</Alert>
							</div>
							<hr />
						</>
					)}
					<div>
						<UploadForm id={id}></UploadForm>
					</div>
				</div>
			)}
			{!loading && !valid && (
				<div className={styles.loading}>
					<h4>Applicaton Not Found</h4>
				</div>
			)}
			{loading && (
				<div className={styles.loading}>
					<Spinner animation="border" size="sm"></Spinner>
					<span>Loading...</span>
				</div>
			)}
		</div>
	);
}
