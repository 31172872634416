import * as React from "react";
import { Form, FormControlProps } from "react-bootstrap";
export type IFileControlProps = {
	type: "file";
	files?: File[];
	onFilesChanged?: (file: File[]) => void;
} & FormControlProps &
	React.HTMLProps<HTMLInputElement>;

export function FileControl({ type, files, onFilesChanged, ...props }: IFileControlProps) {
	const ref = React.useRef<HTMLInputElement>();
	const [value, setValue] = React.useState<string>("");

	React.useEffect(() => {
		if (props.multiple && files.length === 0) {
			setValue("");
		} else if (!props.multiple && !files[0]) {
			setValue("");
		}
	}, [files, props.multiple]);

	return (
		<Form.Control
			{...props}
			ref={ref}
			value={value}
			onChange={(e) => {
				let files: File[] = Array.from(ref.current.files);

				setValue(ref.current.value);
				if (!!onFilesChanged) {
					onFilesChanged(files);
				}
			}}
			type="file"
		/>
	);
}
