import React from "react";
import "./App.scss";
import logo from "../../assets/login-logo-loanhomi.png";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Upload } from "../Upload/Upload";
import { ServiceContext } from "../../Services/ServiceContext";
import { FileService } from "../../Services/FileService";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { Toaster } from "react-hot-toast";
import { Spinner } from "react-bootstrap";

/**
 * Root Application
 * @returns JSX Element
 */
function App() {
	useDocumentTitle("LoanHomi");
	const fileSvc = React.useMemo(() => {
		return new FileService(false);
	}, []);
	return (
		<ServiceContext.Provider value={{ fileService: fileSvc }}>
			<Toaster
				position="top-center"
				gutter={8}
				toastOptions={{
					success: {
						icon: <i className="bi bi-check-circle"></i>,
						className: "bg-success text-white",
					},
					error: {
						icon: <i className="bi bi-exclamation-circle"></i>,
						className: "bg-danger text-white",
					},
					loading: {
						icon: <Spinner animation="border" size="sm"></Spinner>,
					},
				}}
			/>
			<div className="app">
				<div className="app-inner">
					<div className="app-header">
						<img alt="logo" src={logo}></img>
					</div>
					<div className="app-body">
						<Router>
							<Switch>
								<Route
									path="/uploadfiles/:id"
									children={() => {
										return <Upload></Upload>;
									}}
								/>
								<Route path="*">Not Found</Route>
							</Switch>
						</Router>
					</div>
				</div>
			</div>
		</ServiceContext.Provider>
	);
}

export default App;
