import * as React from "react";
import css from "classnames";
export interface ISelectValues {
	key?: string | number;
	label?: string;
}
interface ISelectPropsInternal {
	isInvalid: boolean;
	values: ISelectValues[];
}
export type ISelectProps = ISelectPropsInternal & React.HTMLProps<HTMLSelectElement>;

export function Select({ values, isInvalid, value, ...props }: ISelectProps) {
	const hasEmptyKey = React.useMemo(() => {
		return values.filter((i) => !i.key).length > 0;
	}, [values]);
	let val = value || "";
	return (
		<select
			value={val}
			{...props}
			className={css("form-select", props.className, isInvalid === true && "is-invalid")}>
			{!hasEmptyKey && !value && <option value=""></option>}
			{values.map((i) => {
				return (
					<option key={i.key} value={i.key}>
						{i.label}
					</option>
				);
			})}
		</select>
	);
}
