import { IPickListValue } from "../models/PickListValue";
import { IRequiredDocument } from "../models/RequiredDocument";
import { IUploadNotificationInfo, IUploadNotificationMessage } from "../models/UploadNotification";
import { Subject, ReplaySubject } from "rxjs";
import toast from "react-hot-toast";
/**
 * Class For managing files connected to API
 */
export class FileService {
	constructor(
		/**
		 * Show Toast notifications in Service
		 */
		private showToast: boolean = false
	) {}

	/**
	 * Uploads files to Server
	 * @param loanAppId ID of Application
	 * @param fileType File Type
	 * @param file File to upload
	 */
	public async UploadFile(loanAppId: string, fileType: string, file: File) {
		const formData = new FormData();
		formData.append("body", JSON.stringify({ type: fileType, loanAppId }));
		formData.append("file", file as Blob);

		let resp = await fetch("/api/UploadFile", {
			method: "POST",
			body: formData,
		});
		if (!resp.ok && resp.status === 415) throw new Error("Invalid File Type");
		if (!resp.ok && resp.status === 400) {
			let json = await resp.json();
			throw new Error(json.message);
		}
	}

	/**
	 * Helper function to test long running process
	 * @param to Length of sleep in miliseconds
	 * @returns
	 */
	private sleep(to: number) {
		return new Promise((res, rej) => {
			setTimeout(res, to);
		});
	}

	private docTypesProm: ReplaySubject<IPickListValue[]>;

	/**
	 * Gets the Document Types categories (caches)
	 * @returns Observable of Picklist items
	 */
	public GetDocumentTypes() {
		if (!this.docTypesProm) {
			this.docTypesProm = new ReplaySubject(1);
			this.GetDocumentTypesFromServer().then((i) => {
				this.docTypesProm.next(i);
			});
		}
		return this.docTypesProm.asObservable();
	}

	/**
	 * Gets the Document Types From the API
	 * @returns Promise array of picklist items
	 */
	private async GetDocumentTypesFromServer() {
		let resp = await fetch("/api/DocumentTypes");
		var items: IPickListValue[] = await resp.json();
		return items;
	}

	private fileTypesProm: Promise<string[]>;
	/**
	 * Gets the File types (cache)
	 * @returns file type string array
	 */
	public async GetAllowedFileTypes() {
		if (!this.fileTypesProm) {
			this.fileTypesProm = this.GetAllowedFileTypesFromServer();
		}
		return this.fileTypesProm;
	}

	/**
	 * Gets the File types
	 * @returns file type string array
	 */
	private async GetAllowedFileTypesFromServer() {
		let toastId = this.showToast && toast.loading("Getting allowed file types.");
		let resp = await fetch("/api/AllowedFileType");
		var items: string[] = await resp.json();
		this.showToast && toast.dismiss(toastId);
		return items;
	}

	private requiredDocs: { [id: string]: ReplaySubject<IRequiredDocument[]> } = {};
	/**
	 * Gets documents for a loan app (cache)
	 * @param id Id of loan app
	 * @returns Observable of Required Documents
	 */
	public GetRequiredDocuments(id: string) {
		if (!this.requiredDocs[id]) {
			this.RefreshRequiredDocuments(id);
		}

		return this.requiredDocs[id].asObservable();
	}

	/**
	 * Gets documents for a loan app from server
	 * @param id Id of loan app
	 * @returns Observable of Required Documents
	 */
	private async GetRequiredDocumentsFromServer(id: string) {
		let toastId = this.showToast && toast.loading("Getting required documents");
		let resp = await fetch("/api/RequiredDocuments/" + id);
		var items: IRequiredDocument[] = await resp.json();
		this.showToast && toast.dismiss(toastId);
		return items;
	}

	/**
	 * Force refreshes documents from server
	 * @param id Id of loan app
	 * @returns Observable of Required Documents
	 */
	public async RefreshRequiredDocuments(id: string) {
		if (!this.requiredDocs[id]) {
			this.requiredDocs[id] = new ReplaySubject<IRequiredDocument[]>(1);
		}
		let docs = await this.GetRequiredDocumentsFromServer(id);
		this.requiredDocs[id].next(docs);
		return docs;
	}

	/**
	 * Ensures loan app ID is valid
	 * @param id Loan App ID
	 * @returns bool
	 */
	public async ValidateLoanId(id: string) {
		let toastId = this.showToast && toast.loading("Validating Loan Application");
		let resp = await fetch(`/api/LoanApp/${id}/validate`);

		let json = await resp.json();
		this.showToast && toast.dismiss(toastId);
		return json.isValid as boolean;
	}

	private notifications = new Subject<IUploadNotificationInfo>();

	private notificationId = 0;
	/**
	 * @deprecated
	 */
	public SendNotification(message: IUploadNotificationMessage) {
		this.notificationId++;
		this.notifications.next({
			id: this.notificationId,
			message,
		});

		return this.notificationId;
	}

	private dismissNotification = new ReplaySubject(1);
	/**
	 *  @deprecated
	 */
	public OnNotificationDismiss() {
		return this.dismissNotification.asObservable();
	}

	/**
	 * @deprecated
	 */
	public DismissedNotification(id: number) {
		this.dismissNotification.next(id);
	}

	/**
	 * @deprecated
	 */
	public OnNotification() {
		return this.notifications.asObservable();
	}
}
